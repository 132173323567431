import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `white`,
      marginBottom: `1.45rem`,
      padding: '24px',
      borderBottom: `2px solid #AAA`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `24px 0 0`,
        overflow: 'hidden'
      }}
    >

      <h1 style={{ margin: 0, float: 'left' }}>
        <Link
          to="/"
          style={{
            color: `rgb(63, 127, 191)`,
            textDecoration: `none`,
          }}
        >
          <StaticImage
            src="../images/BCBarfeeds-logo.jpg"
            alt="BCBarfeeds, LLC"
            width={420}
            opacity
          />
        </Link>
      </h1>
      <div style={{
        float: 'right',
        margin: '48px 0 0'
      }}>
        
        <h4>
          <a href="tel:+5622434659">562-243-4659</a><br />
          <a href="mailto:becrawford@mindspring.com">becrawford@mindspring.com</a>
        </h4>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
